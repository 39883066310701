'use strict';

var xtend = require('xtend');

var Schema = require('./schema');

module.exports = merge;

function merge(definitions) {
  var length = definitions.length;
  var property = [];
  var normal = [];
  var index = -1;
  var info;
  var space;

  while (++index < length) {
    info = definitions[index];
    property.push(info.property);
    normal.push(info.normal);
    space = info.space;
  }

  return new Schema(xtend.apply(null, property), xtend.apply(null, normal), space);
}