module.exports = function (hljs) {
  var LISP_IDENT_RE = '[a-zA-Z_\\-\\+\\*\\/\\<\\=\\>\\&\\#][a-zA-Z0-9_\\-\\+\\*\\/\\<\\=\\>\\&\\#!]*';
  var MEC_RE = '\\|[^]*?\\|';
  var LISP_SIMPLE_NUMBER_RE = '(\\-|\\+)?\\d+(\\.\\d+|\\/\\d+)?((d|e|f|l|s|D|E|F|L|S)(\\+|\\-)?\\d+)?';
  var SHEBANG = {
    className: 'meta',
    begin: '^#!',
    end: '$'
  };
  var LITERAL = {
    className: 'literal',
    begin: '\\b(t{1}|nil)\\b'
  };
  var NUMBER = {
    className: 'number',
    variants: [{
      begin: LISP_SIMPLE_NUMBER_RE,
      relevance: 0
    }, {
      begin: '#(b|B)[0-1]+(/[0-1]+)?'
    }, {
      begin: '#(o|O)[0-7]+(/[0-7]+)?'
    }, {
      begin: '#(x|X)[0-9a-fA-F]+(/[0-9a-fA-F]+)?'
    }, {
      begin: '#(c|C)\\(' + LISP_SIMPLE_NUMBER_RE + ' +' + LISP_SIMPLE_NUMBER_RE,
      end: '\\)'
    }]
  };
  var STRING = hljs.inherit(hljs.QUOTE_STRING_MODE, {
    illegal: null
  });
  var COMMENT = hljs.COMMENT(';', '$', {
    relevance: 0
  });
  var VARIABLE = {
    begin: '\\*',
    end: '\\*'
  };
  var KEYWORD = {
    className: 'symbol',
    begin: '[:&]' + LISP_IDENT_RE
  };
  var IDENT = {
    begin: LISP_IDENT_RE,
    relevance: 0
  };
  var MEC = {
    begin: MEC_RE
  };
  var QUOTED_LIST = {
    begin: '\\(',
    end: '\\)',
    contains: ['self', LITERAL, STRING, NUMBER, IDENT]
  };
  var QUOTED = {
    contains: [NUMBER, STRING, VARIABLE, KEYWORD, QUOTED_LIST, IDENT],
    variants: [{
      begin: '[\'`]\\(',
      end: '\\)'
    }, {
      begin: '\\(quote ',
      end: '\\)',
      keywords: {
        name: 'quote'
      }
    }, {
      begin: '\'' + MEC_RE
    }]
  };
  var QUOTED_ATOM = {
    variants: [{
      begin: '\'' + LISP_IDENT_RE
    }, {
      begin: '#\'' + LISP_IDENT_RE + '(::' + LISP_IDENT_RE + ')*'
    }]
  };
  var LIST = {
    begin: '\\(\\s*',
    end: '\\)'
  };
  var BODY = {
    endsWithParent: true,
    relevance: 0
  };
  LIST.contains = [{
    className: 'name',
    variants: [{
      begin: LISP_IDENT_RE
    }, {
      begin: MEC_RE
    }]
  }, BODY];
  BODY.contains = [QUOTED, QUOTED_ATOM, LIST, LITERAL, NUMBER, STRING, COMMENT, VARIABLE, KEYWORD, MEC, IDENT];
  return {
    illegal: /\S/,
    contains: [NUMBER, SHEBANG, LITERAL, STRING, COMMENT, QUOTED, QUOTED_ATOM, LIST, IDENT]
  };
};