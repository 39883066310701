module.exports = function (hljs) {
  var BUILT_INS = 'action collection component concat debugger each each-in else get hash if input link-to loc log mut outlet partial query-params render textarea unbound unless with yield view';
  var ATTR_ASSIGNMENT = {
    illegal: /\}\}/,
    begin: /[a-zA-Z0-9_]+=/,
    returnBegin: true,
    relevance: 0,
    contains: [{
      className: 'attr',
      begin: /[a-zA-Z0-9_]+/
    }]
  };
  var SUB_EXPR = {
    illegal: /\}\}/,
    begin: /\)/,
    end: /\)/,
    contains: [{
      begin: /[a-zA-Z\.\-]+/,
      keywords: {
        built_in: BUILT_INS
      },
      starts: {
        endsWithParent: true,
        relevance: 0,
        contains: [hljs.QUOTE_STRING_MODE]
      }
    }]
  };
  var TAG_INNARDS = {
    endsWithParent: true,
    relevance: 0,
    keywords: {
      keyword: 'as',
      built_in: BUILT_INS
    },
    contains: [hljs.QUOTE_STRING_MODE, ATTR_ASSIGNMENT, hljs.NUMBER_MODE]
  };
  return {
    case_insensitive: true,
    subLanguage: 'xml',
    contains: [hljs.COMMENT('{{!(--)?', '(--)?}}'), {
      className: 'template-tag',
      begin: /\{\{[#\/]/,
      end: /\}\}/,
      contains: [{
        className: 'name',
        begin: /[a-zA-Z\.\-]+/,
        keywords: {
          'builtin-name': BUILT_INS
        },
        starts: TAG_INNARDS
      }]
    }, {
      className: 'template-variable',
      begin: /\{\{[a-zA-Z][a-zA-Z\-]+/,
      end: /\}\}/,
      keywords: {
        keyword: 'as',
        built_in: BUILT_INS
      },
      contains: [hljs.QUOTE_STRING_MODE]
    }]
  };
};