'use strict';

var merge = require('./lib/util/merge');

var xlink = require('./lib/xlink');

var xml = require('./lib/xml');

var xmlns = require('./lib/xmlns');

var aria = require('./lib/aria');

var html = require('./lib/html');

module.exports = merge([xml, xlink, xmlns, aria, html]);