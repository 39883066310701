module.exports = function (hljs) {
  var STRINGS = {
    className: 'string',
    variants: [hljs.inherit(hljs.QUOTE_STRING_MODE, {
      begin: '((u8?|U)|L)?"'
    }), {
      begin: '(u8?|U)?R"',
      end: '"',
      contains: [hljs.BACKSLASH_ESCAPE]
    }, {
      begin: '\'\\\\?.',
      end: '\'',
      illegal: '.'
    }]
  };
  var NUMBERS = {
    className: 'number',
    variants: [{
      begin: '\\b(\\d+(\\.\\d*)?|\\.\\d+)(u|U|l|L|ul|UL|f|F)'
    }, {
      begin: hljs.C_NUMBER_RE
    }],
    relevance: 0
  };
  var PREPROCESSOR = {
    className: 'meta',
    begin: '#',
    end: '$',
    keywords: {
      'meta-keyword': 'if else elif endif define undef ifdef ifndef'
    },
    contains: [{
      begin: /\\\n/,
      relevance: 0
    }, {
      beginKeywords: 'include',
      end: '$',
      keywords: {
        'meta-keyword': 'include'
      },
      contains: [hljs.inherit(STRINGS, {
        className: 'meta-string'
      }), {
        className: 'meta-string',
        begin: '<',
        end: '>',
        illegal: '\\n'
      }]
    }, STRINGS, hljs.C_LINE_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE]
  };
  var DTS_REFERENCE = {
    className: 'variable',
    begin: '\\&[a-z\\d_]*\\b'
  };
  var DTS_KEYWORD = {
    className: 'meta-keyword',
    begin: '/[a-z][a-z\\d-]*/'
  };
  var DTS_LABEL = {
    className: 'symbol',
    begin: '^\\s*[a-zA-Z_][a-zA-Z\\d_]*:'
  };
  var DTS_CELL_PROPERTY = {
    className: 'params',
    begin: '<',
    end: '>',
    contains: [NUMBERS, DTS_REFERENCE]
  };
  var DTS_NODE = {
    className: 'class',
    begin: /[a-zA-Z_][a-zA-Z\d_@]*\s{/,
    end: /[{;=]/,
    returnBegin: true,
    excludeEnd: true
  };
  var DTS_ROOT_NODE = {
    className: 'class',
    begin: '/\\s*{',
    end: '};',
    relevance: 10,
    contains: [DTS_REFERENCE, DTS_KEYWORD, DTS_LABEL, DTS_NODE, DTS_CELL_PROPERTY, hljs.C_LINE_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE, NUMBERS, STRINGS]
  };
  return {
    keywords: "",
    contains: [DTS_ROOT_NODE, DTS_REFERENCE, DTS_KEYWORD, DTS_LABEL, DTS_NODE, DTS_CELL_PROPERTY, hljs.C_LINE_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE, NUMBERS, STRINGS, PREPROCESSOR, {
      begin: hljs.IDENT_RE + '::',
      keywords: ""
    }]
  };
};