'use strict';

var refractorMarkupTemplating = require('./markup-templating.js');

module.exports = ejs;
ejs.displayName = 'ejs';
ejs.aliases = [];

function ejs(Prism) {
  Prism.register(refractorMarkupTemplating);

  (function (Prism) {
    Prism.languages.ejs = {
      delimiter: {
        pattern: /^<%[-_=]?|[-_]?%>$/,
        alias: 'punctuation'
      },
      comment: /^#[\s\S]*/,
      'language-javascript': {
        pattern: /[\s\S]+/,
        inside: Prism.languages.javascript
      }
    };
    Prism.hooks.add('before-tokenize', function (env) {
      var ejsPattern = /<%(?!%)[\s\S]+?%>/g;
      Prism.languages['markup-templating'].buildPlaceholders(env, 'ejs', ejsPattern);
    });
    Prism.hooks.add('after-tokenize', function (env) {
      Prism.languages['markup-templating'].tokenizePlaceholders(env, 'ejs');
    });
  })(Prism);
}