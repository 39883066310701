'use strict';

require("core-js/modules/es6.array.index-of");

var normalize = require('../../normalize');

var Schema = require('./schema');

var DefinedInfo = require('./defined-info');

module.exports = create;

function create(definition) {
  var space = definition.space;
  var mustUseProperty = definition.mustUseProperty || [];
  var attributes = definition.attributes || {};
  var props = definition.properties;
  var transform = definition.transform;
  var property = {};
  var normal = {};
  var prop;
  var info;

  for (prop in props) {
    info = new DefinedInfo(prop, transform(attributes, prop), props[prop], space);

    if (mustUseProperty.indexOf(prop) !== -1) {
      info.mustUseProperty = true;
    }

    property[prop] = info;
    normal[normalize(prop)] = prop;
    normal[normalize(info.attribute)] = prop;
  }

  return new Schema(property, normal, space);
}