module.exports = function (hljs) {
  return {
    subLanguage: 'xml',
    contains: [hljs.COMMENT('<%#', '%>'), {
      begin: '<%[%=-]?',
      end: '[%-]?%>',
      subLanguage: 'ruby',
      excludeBegin: true,
      excludeEnd: true
    }]
  };
};