module.exports = function (hljs) {
  var VAR_IDENT_RE = '[a-z][a-zA-Z0-9_]*';
  var CHAR = {
    className: 'string',
    begin: '\\$.{1}'
  };
  var SYMBOL = {
    className: 'symbol',
    begin: '#' + hljs.UNDERSCORE_IDENT_RE
  };
  return {
    aliases: ['st'],
    keywords: 'self super nil true false thisContext',
    // only 6
    contains: [hljs.COMMENT('"', '"'), hljs.APOS_STRING_MODE, {
      className: 'type',
      begin: '\\b[A-Z][A-Za-z0-9_]*',
      relevance: 0
    }, {
      begin: VAR_IDENT_RE + ':',
      relevance: 0
    }, hljs.C_NUMBER_MODE, SYMBOL, CHAR, {
      // This looks more complicated than needed to avoid combinatorial
      // explosion under V8. It effectively means `| var1 var2 ... |` with
      // whitespace adjacent to `|` being optional.
      begin: '\\|[ ]*' + VAR_IDENT_RE + '([ ]+' + VAR_IDENT_RE + ')*[ ]*\\|',
      returnBegin: true,
      end: /\|/,
      illegal: /\S/,
      contains: [{
        begin: '(\\|[ ]*)?' + VAR_IDENT_RE
      }]
    }, {
      begin: '\\#\\(',
      end: '\\)',
      contains: [hljs.APOS_STRING_MODE, CHAR, hljs.C_NUMBER_MODE, SYMBOL]
    }]
  };
};