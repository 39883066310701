'use strict';

module.exports = parse;
var numberSign = 35; //  '#'

var dot = 46; //  '.'
// Create a hast element from a simple CSS selector.

function parse(selector, defaultTagName) {
  var value = selector || '';
  var name = defaultTagName || 'div';
  var props = {};
  var index = -1;
  var length = value.length;
  var className;
  var type;
  var code;
  var subvalue;
  var lastIndex;

  while (++index <= length) {
    code = value.charCodeAt(index);

    if (!code || code === dot || code === numberSign) {
      subvalue = value.slice(lastIndex, index);

      if (subvalue) {
        if (type === dot) {
          // eslint-disable-next-line max-depth
          if (className) {
            className.push(subvalue);
          } else {
            className = [subvalue];
            props.className = className;
          }
        } else if (type === numberSign) {
          props.id = subvalue;
        } else {
          name = subvalue;
        }
      }

      lastIndex = index + 1;
      type = code;
    }
  }

  return {
    type: 'element',
    tagName: name,
    properties: props,
    children: []
  };
}